<template>
<div>
  <span>{{ label }}</span>
  <div class="d-flex">
    <div class="w-100 d-flex flex-column mr-2">
      <v-slider
        v-model="valueModel"
        :max="max"
        :min="min"
        step="900000"
        :rules="rules"
        :error="error"
        hide-details
        @input="$emit('input', $event)"
      />
      <div class="d-flex justify-space-between">
        <span :class="error ? 'error--text' : 'primary--text'" class="text-caption">{{ min | dateFormat('HH:mm') }}</span>
        <span :class="error ? 'error--text' : 'primary--text'" class="text-caption primary--text">{{ max | dateFormat('HH:mm') }}</span>
      </div>
      <div style="height:15px" class="text-right error--text">
        <span v-if="error">
          {{ errorMessages }}
        </span>
      </div>
    </div>
    <span class="text-right black--text" style="width:35px">{{ dateFormat(valueModel, 'HH:mm') }}</span>
  </div>
</div>
</template>

<script>
import * as moment from 'moment'
import dateFormat from '@/filters/dateFormat'

export default {
  name: 'HourInput',
  props: {
    value: {
      type: Number,
      default: moment().startOf('day').valueOf()
    },
    label: {
      type: String,
      default: ''
    },
    min: {
      type: [String, Number],
      default: moment().startOf('day').valueOf()
    },
    max: {
      type: [String, Number],
      default: moment().endOf('day').subtract(14, 'minute').valueOf()
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => ([])
    }
  },
  computed: {
    valueModel: {
      get () {
        return this.value
      },
      set () {}
    }
  },
  methods: {
    dateFormat
  }
}
</script>

<style lang="scss">
.hour-input {
  .v-messages {
    min-height: 0 !important;
  }
  .v-input__slot {
    margin: 0;
  }
  .v-text-field__slot input {
    text-align: center;
  }
}
</style>
